import React, { Component } from "react";
import { graphql } from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import PostCompetencies from "../components/post-competencies";
import Layout from "../layouts";
// import HeroImage from "../components/heroimage";
import { Helmet } from "react-helmet";
import { rhythm } from "../utils/typography";
import {
  PaddedMobile,
  PostMain,
  PostTitleItems,
  MarginTopPost,
  GridHeader,
  GridBox,
  GridSectionHeader,
  GridBoxContainer,
  PageSpacing,
  Accent,
} from "../utils/styles";
import { Flex, Box } from "@rebass/grid/emotion"; //https://github.com/rebassjs/grid
import { css } from "@emotion/core"; // https://github.com/gatsbyjs/gatsby/blob/master/examples/using-emotion/src/pages/index.js
import Img from "gatsby-image";

const AdditionalHeader = css`
  /* margin-top: ${rhythm(2)}; */
  /* font-weight: 300; */
  /* color: ${Accent}; */
  font-weight: 300;
  color: black;
  /* margin-top: ${rhythm(1)}; */
  @media (min-width: 40em) {
    margin-top: 0;
  }
`;

class StudentTemplate extends Component {
  render() {
    const student = this.props.data.wordpressWpStudent;
    const posts = this.props.data.allWordpressPost;
    return (
      <Layout>
        <Helmet
          title={`MA User Experience Design | student > ${student.title}`}
          meta={[{ name: "description", content: student.content }]}
        />
        <PostMain css={MarginTopPost}>
          <Flex
            mx={[-1, -1, -2]}
            flexWrap="wrap"
            css={[PageSpacing, PaddedMobile]}
          >
            <Box width={[1, 1 / 2, 1 / 2]} px={[1, 1, 2]} key={`student-image`}>
              {student.featured_media && (
                <Img
                  css={{ marginBottom: rhythm(1), height: `35vh` }}
                  key={
                    student.featured_media.localFile.childImageSharp.fluid.src
                  }
                  fluid={student.featured_media.localFile.childImageSharp.fluid}
                />
              )}
              <PostTitleItems>
                <h1 dangerouslySetInnerHTML={{ __html: student.title }} />
                <AniLink
                  fade
                  duration={0.6}
                  to={`/show/${student.slug}`}
                  css={{ textDecoration: `none` }}
                >
                  {student.acf.final_major_project && (
                    <h3 css={AdditionalHeader}>Final Major Project</h3>
                  )}
                </AniLink>
              </PostTitleItems>

              <div>
                <PostCompetencies
                  node={student}
                  css={{ marginBottom: rhythm(1) }}
                />
              </div>
            </Box>
            <Box
              width={[1, 1 / 2, 1 / 2]}
              px={[1, 1, 2]}
              py={[4, 0, 0]}
              key={`student-info`}
            >
              <div dangerouslySetInnerHTML={{ __html: student.content }} />
            </Box>
          </Flex>

          <Flex
            mx={[-1, -1, -2]}
            flexWrap="wrap"
            css={[PageSpacing, PaddedMobile, css``]}
          >
            <Box width={1} px={[1, 1, 2]} key={`box-header`}>
              {posts.edges.length > 0 && (
                <h2 css={GridSectionHeader}>Projects</h2>
              )}
            </Box>
            {posts.edges.map(({ node }) => (
              <Box
                width={[1 / 2, 1 / 2, 1 / 4]}
                px={[1, 1, 2]}
                key={`box-${node.slug}`}
                css={GridBoxContainer}
              >
                <div css={GridBox} key={node.slug}>
                  <AniLink
                    fade
                    duration={0.6}
                    to={`/${node.slug}`}
                    css={{ textDecoration: `none` }}
                  >
                    {node.featured_media &&
                      node.featured_media.localFile &&
                      node.featured_media.localFile.childImageSharp && (
                        <Img
                          // css={{ marginBottom: rhythm(1/2) }}
                          key={
                            node.featured_media.localFile.childImageSharp.fluid
                              .src
                          }
                          fluid={
                            node.featured_media.localFile.childImageSharp.fluid
                          }
                        />
                      )}
                    <h3 css={GridHeader}>{node.title}</h3>
                  </AniLink>
                  {/* <div dangerouslySetInnerHTML={{ __html: node.excerpt }} /> */}
                  {/* <PostIcons node={node} /> */}
                </div>
              </Box>
            ))}
          </Flex>
        </PostMain>
      </Layout>
    );
  }
}

export default StudentTemplate;

export const pageQuery = graphql`
  query($id: String!, $wordpress_id: Int!) {
    wordpressWpStudent(id: { eq: $id }) {
      title
      slug
      content
      date(formatString: "MMMM DD, YYYY")
      categories {
        slug
        name
      }
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      acf {
        start_year
        final_major_project {
          image {
            id
          }
        }
      }
    }
    site {
      id
      siteMetadata {
        title
        subtitle
      }
    }
    allWordpressPost(
      sort: { fields: [date] }
      filter: {
        wordpress_id: { ne: 2320 }
        acf: { project_students: { in: [$wordpress_id] } }
      }
    ) {
      edges {
        node {
          title
          wordpress_id
          excerpt
          slug
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 680) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
